import styled, { css } from 'styled-components'

import { Button as Btn } from 'reactstrap'

import quizNextButton from '../../images/quiz/quiz_further_button_bg.svg'
import quizTrueButton from '../../images/quiz/quiz_true_button_bg.svg'
import quizFalseButton from '../../images/quiz/quiz_false_button_bg.svg'
import quizQuestionBorderTopBG from '../../images/quiz/quiz_question_border_top.svg'
import quizQuestionBorderBottomBG from '../../images/quiz/quiz_question_border_bottom.svg'
import quizQuestionBorderTopMobileBG from '../../images/quiz/quiz_question_border_top_mobile.svg'
import quizQuestionBorderBottomMobileBG from '../../images/quiz/quiz_question_border_bottom_mobile.svg'

export const QuizDesc = styled.div`
  background-color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 36px;
  text-align: center;
  line-height: 1;
  word-spacing: 3px;
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  border-radius: 72px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
    padding-top: 35px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 35px;
    border-radius: 65px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
    word-spacing: 2px;
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 30px;
    border-radius: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px;
    word-spacing: 2px;
    padding-top: 25px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    border-radius: 50px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    word-spacing: 1px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-radius: 40px;
  }
  p {
    margin-bottom: 35px;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 25px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const quizDescMixin = css/* styled */ `
  font-size: 36px;
  text-align: center;
  line-height: 1;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 28px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 24px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
  p {
    margin-bottom: 35px;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-bottom: 30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 25px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      margin-bottom: 20px;
    }
    @media (max-width: 575px) {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const quizButtonMixin = css/* styled */ `
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.2s ease;
  cursor: pointer;
  ${(props) =>
    props.next &&
    `
    background-image: url(${quizNextButton});
  `}
  ${(props) =>
    props.true &&
    `
    background-image: url(${quizTrueButton});
  `}
  ${(props) =>
    props.false &&
    `
    background-image: url(${quizFalseButton});
  `}
  ${(props) =>
    props.position === 'left' &&
    `
    margin-right: 20px;
  `}
  ${(props) =>
    props.position === 'right' &&
    `
    margin-left: 20px;
  `}
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 230px;
    height: 100px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 180px;
    height: 77px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 150px;
    height: 64px;
  }
  @media (max-width: 575px) {
    width: 120px;
    height: 51px;
  }
  &:hover,
  &:focus {
    opacity: 0.85;
  }
`

export const QuizButton = styled(Btn)`
  position: relative;
  background-color: #4790dd;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontVT323};
  font-size: 46px;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  /* padding-top: 5px; */
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 0.8rem;
  ${(props) =>
    props.left &&
    `
    margin-right: 20px;
  `}
  ${(props) =>
    props.right &&
    `
    margin-left: 20px;
  `}
  border: 3px solid ${(props) => props.theme.colorWhite};
  border-radius: 10px;
  ${(props) =>
    props.bg &&
    `
    background-color: ${props.bg};
  `}
  ${(props) =>
    props.next &&
    `
    background-color: #4790dd;
  `}
  ${(props) =>
    props.understand &&
    `
    background-color: #008552;
  `}
  ${(props) =>
    props.easy &&
    `
    background-color: #00b380;
  `}
  ${(props) =>
    props.hard &&
    `
    background-color: #1f72ae;
  `}
  ${(props) =>
    props.start &&
    `
    background-color: #008552;
  `}
  ${(props) =>
    props.true &&
    `
    background-color: #4790dd;
  `}
  ${(props) =>
    props.false &&
    `
    background-color: #adadad;
  `}
  ${(props) =>
    props.nextquestion &&
    `
    background-color: #008552;
  `}
  ${(props) =>
    props.score &&
    `
    background-color: #008552;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 40px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 40px;
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 38px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 34px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: 575px) {
    font-size: 22px;
    padding-left: 30px;
    padding-right: 30px;
    border: 2px solid ${(props) => props.theme.colorWhite};
    border-radius: 6px;
  }
  &:hover,
  &:focus {
    background-color: #4188ba;
    border: 3px solid ${(props) => props.theme.colorWhite}!important;
    box-shadow: none;
    outline: none;
    ${(props) =>
      props.next &&
      `
    background-color: #4188ba!important;
  `}
    ${(props) =>
      props.understand &&
      `
    background-color: #038f5c!important;
  `}
  ${(props) =>
      props.easy &&
      `
    background-color: #1cba8d!important;
  `}
  ${(props) =>
      props.hard &&
      `
    background-color: #2a7fbd!important;
  `}
  ${(props) =>
      props.start &&
      `
    background-color: #0fa169!important;
  `}
  ${(props) =>
      props.true &&
      `
    background-color: #5ca5f2!important;
  `}
  ${(props) =>
      props.false &&
      `
    background-color: #cac7c7!important;
  `}
  ${(props) =>
      props.nextquestion &&
      `
    background-color: #038f5c!important;
  `}
  ${(props) =>
      props.score &&
      `
    background-color: #0f9662!important;
  `}
  }
`

export const quizDescBorderRadiusMixin = css/* styled */ `
  padding-top: 8%;
  padding-bottom: 8%;
  border-radius: 82px;
  @media (max-width: 575px) {
    border-radius: 40px;
  }
`

export const quizQuestionBordersWrapperMixin = css/* styled */ `
  position: relative;
  background-color: ${(props) => props.theme.colorWhite};
  padding-top: 6%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 6%;
  border-left: 10px solid #1d1d1b;
  border-right: 10px solid #1d1d1b;
  @media (min-width: 768px) and (max-width: 991px) {
    border-left: 8px solid #1d1d1b;
    border-right: 8px solid #1d1d1b;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    border-left: 6px solid #1d1d1b;
    border-right: 6px solid #1d1d1b;
  }
  @media (max-width: 575px) {
    padding-bottom: 10%;
    border-left: 4px solid #1d1d1b;
    border-right: 4px solid #1d1d1b;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    left: -15px;
    right: -15px;
    display: block;
    height: 40px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (min-width: 576px) and (max-width: 767px) {
      left: -10px;
      right: -10px;
    }
    @media (max-width: 575px) {
      left: -5px;
      right: -5px;
    }
  }
  &:after {
    top: 0;
    background-image: url(${quizQuestionBorderTopBG});
    background-position-y: bottom;
    margin-top: 1px;
    transform: translateY(-100%);
    @media (max-width: 575px) {
      background-image: url(${quizQuestionBorderTopMobileBG});
    }
  }
  &:before {
    background-image: url(${quizQuestionBorderBottomBG});
    background-position-y: top;
    bottom: 0;
    margin-bottom: 1px;
    transform: translateY(100%);
    @media (max-width: 575px) {
      background-image: url(${quizQuestionBorderBottomMobileBG});
    }
  }
`
