import React, { useEffect, useState } from 'react'
import { withPrefix } from 'gatsby'
import { Howl, Howler } from 'howler'
import useCountDown from 'react-countdown-hook'
import Confetti from 'react-confetti'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import QuizHeader from '../../components/quiz/quiz-header'
import QuizStartSound from '../../components/quiz/quiz-first-start'
import QuizStart from '../../components/quiz/quiz-start'
import QuizRules from '../../components/quiz/quiz-rules'
import QuizDifficulty from '../../components/quiz/quiz-difficulty'
import QuizInputName from '../../components/quiz/quiz-input-name'
import QuizQuestionRadio from '../../components/quiz/quiz-question-radio'
import QuizQuestionMultiple from '../../components/quiz/quiz-question-multiple'
import QuizFinalScore from '../../components/quiz/quiz-final-score'
import QuizTimeout from '../../components/quiz/quiz-timeout'
import QuizGiveInvitation from '../../components/quiz/quiz-give-invitation'
import QuizGetInvitation from '../../components/quiz/quiz-get-invitation'
import QuizCollectiveScore from '../../components/quiz/quiz-collective-score'
import QuizStatusBar from '../../components/quiz/quiz-status-bar'

import { QUESTIONS_EASY, QUESTIONS_HARD } from '../../components/quiz/questions'
import { getPlayer, updatePlayers } from '../../components/quiz/api'
import { dlEvent } from '../../components/events'

const PageOutterWrapper = styled.div`
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  background-color: #0cd199;
  background-image: url(${require(`../../images/quiz/bg/quiz_stages_bg.svg`)});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 575px) {
    background-image: url(${require(`../../images/quiz/bg/quiz_stages_bg_mobile.svg`)});
  }
`

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
  min-height: 100vh;
  ${(props) =>
    props.step
      ? `
    background-image: url(${require(`../../images/quiz/bg/quiz_stage_${props.step}_bg.svg`)});
  `
      : `
  background-image: url(${require(`../../images/quiz/bg/quiz_stage_1_bg.svg`)});
  `}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media (max-width: 575px) {
    ${(props) =>
      props.stage
        ? `
    background-image: url(${require(`../../images/quiz/bg/quiz_stage_${props.step}_bg_mobile.svg`)});
  `
        : `
  background-image: url(${require(`../../images/quiz/bg/quiz_stage_1_bg_mobile.svg`)});
  `}
  }
`

const PageInnerBodyWrapper = styled.div`
  position: relative;
  z-index: 100;

  @media (max-width: 400px) and (max-height: 599px) {
    transform: scale(0.8);
  }

  @media (min-width: 1024px) and (max-height: 1000px) {
    transform: scale(0.8);
  }
`

const PageConfetti = styled(Confetti)`
  width: 100% !important;
  height: 100% !important;
  z-index: 10;
`

const IndexPage = ({ location }) => {
  const [step, setStep] = useState(0)
  const [withSound, setWithSound] = useState(true)
  const [backgroundSound, setBackgroundSound] = useState(
    new Howl({
      src: withPrefix('/sounds/The_Lighter_Side_30 sek.mp3'),
      loop: true,
    })
  )
  const [score, setScore] = useState(0)
  const [difficulty, setDifficulty] = useState('easy')
  const [userName, setUserName] = useState('')
  const [questionNumber, setQuestionNumber] = useState(0)
  const [maxQuestionNumber, setMaxQuestionNumber] = useState(1)
  const [questionDict, setQuestionDict] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [isInvite, setIsInvite] = useState()
  const [invitationData, setInvitationData] = useState()
  const [invitedPlayerPosition, setInvitedPlayerPosition] = useState()

  const backgroundAudio = new Howl({
    src: withPrefix('/sounds/The_Lighter_Side_30 sek.mp3'),
    loop: true,
  })

  const changeStep = () => {
    if (!isInvite) {
      setStep(step + 1)
    } else {
      if (step === 8) {
        setStep(4)
      } else if (step === 6) {
        setIsLoading(true)
        updatePlayers(
          invitationData.id,
          userName,
          score,
          invitedPlayerPosition
        ).then((resp) => {
          if (resp.status === 200) {
            setInvitationData({
              ...invitationData,
              players: resp.data.players,
            })
            setInvitedPlayerPosition(resp.data.playerPosition)
            setIsLoading(false)
            setStep(10)
          }
        })
      } else if (step === 6) {
        setStep(10)
      } else {
        setStep(step + 1)
      }
    }
  }

  const changeWithSound = () => {
    setWithSound(!withSound)

    if (!withSound) {
      backgroundSound.play()
      backgroundSound.volume(0.1)
    } else {
      backgroundSound.stop()
      Howler.stop()
    }
  }

  const changeDifficulty = (difficulty) => {
    setDifficulty(difficulty)
    setMaxQuestionNumber(difficulty === 'easy' ? 10 : 20)

    // get random questions
    if (difficulty === 'easy') {
      setQuestionDict(
        QUESTIONS_EASY.sort(() => 0.5 - Math.random()).slice(0, 10)
      )
    } else {
      setQuestionDict(
        QUESTIONS_HARD.sort(() => 0.5 - Math.random()).slice(0, 20)
      )
    }
  }

  const changeUserName = (name) => {
    setUserName(name)
  }

  const changeQuestion = (isRight) => {
    if (isRight) {
      setScore(score + 2)
    }

    if (questionNumber === maxQuestionNumber - 1) {
      changeStep(6)
    } else {
      setQuestionNumber(questionNumber + 1)
    }
  }

  const [timeLeft, { start, pause }] = useCountDown(30000, 1000)

  useEffect(() => {
    if (step === 1) {
      if (location.search && location.search.startsWith('?id=')) {
        const playerId = location.search.substr(4)
        getPlayer(playerId)
          .then((resp) => {
            setIsInvite(true)
            setInvitationData(resp.data)
            changeDifficulty(resp.data.difficulty)
            setStep(8)
          })
          .catch(() => {
            alert(
              'Nie mogę znaleźć zaproszenia. Sprawdź czy link jest poprawny.'
            )
            window.location.href = withPrefix('/quiz')
          })
      } else {
        setIsInvite(false)
      }
    }

    if (step === 1) {
      if (withSound) {
        backgroundSound.play()
        backgroundSound.volume(0.1)
      }
    }

    if (step === 5) {
      start(30000)
    }
  }, [questionNumber, step])

  useEffect(() => {
    if (step !== 5) return

    if (timeLeft <= 0) {
      setStep(11)
    }
  }, [timeLeft])

  return (
    <Layout page="quiz">
      <SEO title="Zagraj w samodzielność" />

      <PageOutterWrapper id="pageOutterWrapper">
        <PageWrapper step={step}>
          {step === 10 && <PageConfetti />}
          <QuizHeader
            step={step}
            sound={withSound}
            changeWithSound={changeWithSound}
            score={score}
            timeLeft={timeLeft / 1000}
          />

          <PageInnerBodyWrapper>
            {step === 0 && (
              <>
                <QuizStartSound
                  changeStep={changeStep}
                  sound={withSound}
                  changeWithSound={changeWithSound}
                />
              </>
            )}

            {isInvite === false && step === 1 && (
              <QuizStart withSound={withSound} changeStep={changeStep} />
            )}
            {isInvite && step === 8 && (
              <QuizGetInvitation
                userName={invitationData.name}
                withSound={withSound}
                changeStep={changeStep}
              />
            )}

            {step === 2 && (
              <QuizRules withSound={withSound} changeStep={changeStep} />
            )}
            {step === 3 && (
              <QuizDifficulty
                withSound={withSound}
                changeStep={changeStep}
                changeDifficulty={changeDifficulty}
              />
            )}
            {step === 4 && (
              <QuizInputName
                withSound={withSound}
                changeStep={changeStep}
                setUserName={changeUserName}
              />
            )}

            {step === 5 && (
              <>
                {questionDict[questionNumber].type === 'choice' && (
                  <QuizQuestionMultiple
                    question={questionDict[questionNumber]}
                    withSound={withSound}
                    changeQuestion={changeQuestion}
                    onAnswer={() => pause()}
                    questionNumber={questionNumber + 1}
                  />
                )}
                {questionDict[questionNumber].type === 'bool' && (
                  <QuizQuestionRadio
                    question={questionDict[questionNumber]}
                    withSound={withSound}
                    changeQuestion={changeQuestion}
                    onAnswer={() => pause()}
                    questionNumber={questionNumber + 1}
                  />
                )}
              </>
            )}

            {step === 6 && (
              <QuizFinalScore
                score={score}
                maxNumber={maxQuestionNumber}
                withSound={withSound}
                changeStep={changeStep}
                buttonText={isInvite ? `Pokaż wyniki` : `Zmierz się z bliskimi`}
                isLoading={isLoading}
                isInvite={isInvite}
              />
            )}
            {step === 11 && (
              <QuizTimeout
                withSound={withSound}
                changeStep={() => {
                  if (questionNumber + 1 >= maxQuestionNumber) {
                    setStep(6)
                  } else {
                    setQuestionNumber(questionNumber + 1)
                    setStep(5)
                  }
                }}
                isLoading={isLoading}
                isInvite={isInvite}
              />
            )}
            {step === 10 && (
              <QuizCollectiveScore
                withSound={withSound}
                invitationData={invitationData}
                changeStep={() => {
                  setQuestionNumber(0)
                  setScore(0)
                  setStep(5)
                }}
              />
            )}

            {step === 7 && (
              <QuizGiveInvitation
                name={userName}
                score={score}
                difficulty={difficulty}
                withSound={withSound}
                changeStep={changeStep}
              />
            )}

            {step === 5 && (
              <QuizStatusBar
                number={questionNumber + 1}
                maxNumber={maxQuestionNumber}
                withSound={withSound}
                percent={Math.floor((questionNumber / maxQuestionNumber) * 100)}
              />
            )}
          </PageInnerBodyWrapper>

          <div className="bottomSpacer" />
        </PageWrapper>
      </PageOutterWrapper>
    </Layout>
  )
}

export default IndexPage
