import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import quizStatusBarLeft from '../../images/quiz/quiz_status_bar_left.svg'
import quizStatusBarRight from '../../images/quiz/quiz_status_bar_right.svg'

const QuizStatusBarWrapper = styled.div``

const QuizStatusBarColWrapper = styled(Col)`
  text-align: center;
  padding-left: 25%;
  padding-right: 25%;
`

const QuizStatusBarProgress = styled.p`
  display: inline-block;
  background-color: #27daa7;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 58px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
  margin-bottom: 20px;

  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 42px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 34px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

const QuizStatusBarLine = styled.div`
  position: relative;
  height: 20px;
  background-color: ${(props) => props.theme.colorWhite};
  border-top: 4px solid ${(props) => props.theme.colorBlock};
  border-bottom: 4px solid ${(props) => props.theme.colorBlock};
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    display: block;
    width: 10px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &:before {
    left: -10px;
    background-image: url(${quizStatusBarLeft});
    background-position-x: right;
  }
  &:after {
    right: -10px;
    background-image: url(${quizStatusBarRight});
    background-position-x: left;
  }
`

const QuizStatusBarLineProgress = styled.span`
  position: absolute;
  top: 2px;
  left: 3px;
  right: 3px;
  bottom: 2px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colorWhite};
  border-radius: 4px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #00b836;
    display: block;
    width: ${(props) => props.percent}%;
  }
`

const QuizStatusBar = ({ percent, number, maxNumber }) => (
  <QuizStatusBarWrapper>
    <Container>
      <Row>
        <QuizStatusBarColWrapper>
          <QuizStatusBarProgress>
            pytanie {number}/{maxNumber}
          </QuizStatusBarProgress>
          <QuizStatusBarLine>
            <QuizStatusBarLineProgress percent={percent} />
          </QuizStatusBarLine>
        </QuizStatusBarColWrapper>
      </Row>
    </Container>
  </QuizStatusBarWrapper>
)

export default QuizStatusBar
