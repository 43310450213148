import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizDesc, QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizRulestTitleBG from '../../images/quiz/quiz_rules_title_line_bg.svg'

const QuizRulesWrapper = styled.div`
  padding-bottom: 50px;

  @media (min-width: 1200px) {
    margin-top: -10%;
  }

  @media (max-width: 767px) {
    margin-top: -30px;
    padding-bottom: 10px;
  }
`

const QuizRulesTitle = styled.h2`
  background-image: url(${quizRulestTitleBG});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 25%;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 76px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 40px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 60px;
    margin-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 50px;
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    font-size: 41px;
    margin-bottom: 15px;
  }
  @media (max-width: 340px) {
    font-size: 39px;
  }
`

const QuizRulesDesc = styled(QuizDesc)`
  line-height: 1.3;
  @media (min-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`

const QuizRulesButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizRulesButton = styled(QuizButton)``

const QuizRules = ({ changeStep, withSound }) => {
  const buttonAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_kliknij_rozumiem.mp3'),
  })
  const initialAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_zasady_gry.mp3'),
  })

  useEffect(() => {
    if (withSound) {
      initialAudio.play()
      initialAudio.on('end', () => {
        setTimeout(() => {
          buttonAudio.play()
        }, 1000)
      })
    }
  }, [])

  const handleStepChange = () => {
    initialAudio.stop()
    buttonAudio.stop()
    dlQuizEvent({
      action: 'Kliknięcie w button - ROZUMIEM',
      label: 'Quiz: Zasady gry',
    })
    changeStep()
  }

  return (
    <QuizRulesWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizRulesTitle>zasady gry</QuizRulesTitle>
          </Col>
          <Col xs={12}>
            <QuizRulesDesc>
              <p>
                Każde pytanie quizu ma{'\u00A0'}określony czas na{'\u00A0'}
                odpowiedź. Przeczytaj je{'\u00A0'}uważnie i{'\u00A0'}wybierz
                odpowiedź, która Twoim zdaniem jest prawidłowa.{' '}
              </p>
              <p>
                Uważaj, bo{'\u00A0'}czasem wszystkie odpowiedzi będą dobre.
                Wtedy zaznacz tę{'\u00A0'}opcję na{'\u00A0'}planszy. Jeśli nie
                wskażesz prawidłowej odpowiedzi w{'\u00A0'}wyznaczonym czasie,
                dostaniesz 0 punktów.
              </p>
              <p>
                Za{'\u00A0'}prawidłową odpowiedź dostaniesz 2{'\u00A0'}punkty,
                za{'\u00A0'}nieprawidłową - 0. Powodzenia!
              </p>
            </QuizRulesDesc>
          </Col>
          <QuizRulesButtonWrapper xs={12}>
            <QuizRulesButton
              onClick={handleStepChange}
              understand
              id="quiz-rules-button"
            >
              rozumiem
            </QuizRulesButton>
          </QuizRulesButtonWrapper>
        </Row>
      </Container>
    </QuizRulesWrapper>
  )
}

export default QuizRules
