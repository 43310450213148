import React from 'react'
import { withPrefix } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import quizTitleBG from '../../images/quiz/quiz_header_title_bg.svg'
import quizSpeakerIcon from '../../images/quiz/quiz_speaker.svg'
import quizSpeakerNoSoundIcon from '../../images/quiz/quiz_speaker_no_sound.svg'
import quizTimerIcon from '../../images/quiz/quiz_hourglass_icon.svg'
import quizGameFinishIcon from '../../images/quiz/game_finish_icon.svg'

const QuizHeaderWrapper = styled.div`
  position: relative;
  z-index: 120;
  ${(props) =>
    props.step === 5 &&
    `
    z-index: 100;
  `}
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 40px;
  }
  @media (max-width: 575px) {
    padding-bottom: 50px;
  }
`

const QuizHeaderContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  .row {
    @media (max-width: 575px) {
      margin-right: 0;
    }
  }
`

const QuizHeaderCol = styled(Col)`
  position: relative;
`

const QuizHeaderInnerWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const QuizHeaderTitle = styled.h1`
  display: inline-block;
  background-image: url(${quizTitleBG});
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto 100%;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 58px;
  font-weight: 700;
  line-height: 0.8;
  padding-top: 45px;
  padding-left: 70px;
  padding-right: 70px;
  padding-bottom: 45px;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 48px;
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 44px;
    padding-top: 40px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 40px;
    padding-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 35px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
    padding-top: 35px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 35px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;
  }
  span {
    &:first-of-type {
      color: ${(props) => props.theme.colorWhite};
    }
    &:last-of-type {
      color: #ffd100;
    }
  }
`

const QuizHeaderBottomIconsWrapper = styled.div`
  position: absolute;
  left: 90px;
  bottom: -15px;
  transform: translateY(100%);
  ${(props) =>
    props.step !== 5 &&
    `
    z-index: 100;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    left: 72px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    left: 80px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    left: 70px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    left: 61px;
  }
  @media (max-width: 575px) {
    left: 42px;
  }
`

const QuizHeaderFinishLink = styled.a`
  display: inline-block;
  float: left;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-weight: 700;
  line-height: 0.8;
  text-transform: uppercase;
  transition: all 0.2s ease;
  &:hover {
    color: ${(props) => props.theme.colorYellow};
  }
  .wrapper {
    display: inline-block;
    width: 150px;
    height: 60px;
    font-size: 30px;
    padding-top: 14px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 140px;
      height: 55px;
      font-size: 26px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 120px;
      height: 50px;
      font-size: 24px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 110px;
      height: 45px;
      font-size: 22px;
      padding-top: 10px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 110px;
      height: 40px;
      font-size: 22px;
      padding-top: 10px;
    }
    @media (max-width: 575px) {
      width: 100px;
      height: 30px;
      font-size: 20px;
      padding-top: 6px;
    }
  }
`

const QuizHeaderSpeaker = styled.div`
  float: left;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  background-image: ${(props) =>
    props.sound
      ? `url(${quizSpeakerIcon})`
      : `
    url(${quizSpeakerNoSoundIcon})
  `};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 85px;
  transition: opacity 0.2s ease;
  cursor: pointer;
  z-index: 100;
  ${(props) =>
    props.step === 5 &&
    `
    margin-right: 35px;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 55px;
    height: 55px;
    margin-right: 70px;
    ${(props) =>
      props.step === 5 &&
      `
      margin-right: 30px;
    `}
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 50px;
    height: 50px;
    margin-right: 70px;
    ${(props) =>
      props.step === 5 &&
      `
      margin-right: 30px;
    `}
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 45px;
    height: 45px;
    margin-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }
  @media (max-width: 575px) {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  &:hover,
  &:focus {
    opacity: 0.85;
  }
`

const QuizHeaderTimer = styled.div`
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 180px;
  height: 100px;
  background-image: url(${quizTimerIcon});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontVT323};
  font-size: 80px;
  font-weight: 700;
  line-height: 1;
  margin-right: 85px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    width: 150px;
    height: 80px;
    font-size: 70px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 150px;
    height: 80px;
    font-size: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    width: 130px;
    height: 65px;
    font-size: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 130px;
    height: 65px;
    font-size: 60px;
  }
  @media (max-width: 575px) {
    width: 90px;
    height: 50px;
    font-size: 50px;
    margin-right: 40px;
  }
`

const QuizHeaderGameFinish = styled.div`
  margin-right: 85px;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-right: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-right: 40px;
  }
  @media (max-width: 575px) {
    margin-right: 15px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-right: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-right: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    margin-right: 40px;
  }
  @media (max-width: 575px) {
    margin-right: 15px;
  }
  .gameFinishIcon {
    width: 100px;
    height: 100px;
    background-image: url(${quizGameFinishIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transition: all 0.2s ease;
    cursor: pointer;
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 80px;
      height: 80px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 70px;
      height: 70px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 60px;
      height: 60px;
    }
    @media (max-width: 575px) {
      width: 40px;
      height: 40px;
    }
    &:hover,
    &:focus {
      opacity: 0.85;
    }
  }
  span {
    display: block;
    color: ${(props) => props.theme.colorWhite};
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 5px;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 28px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 24px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 20px;
    }
    @media (max-width: 575px) {
      font-size: 14px;
      margin-bottom: 3px;
    }
  }
`

const QuizHeaderScores = styled.p`
  position: absolute;
  left: 50%;
  bottom: 0;
  display: inline-block;
  background-color: #27daa7;
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 58px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.2;
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 0;
  transform: translateX(-50%);
  z-index: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 46px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 34px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: -90px;
    transform: translateX(-50%);
  }
  span {
    color: ${(props) => props.theme.colorBlack};
  }
`

export const getPointsWord = (points) => {
  if (points === 1) return `punkt`
  if (points > 1 && points < 5) return `punkty`
  return `punktów`
}

const QuizHeader = (props) => {
  const { sound, step, score, timeLeft } = props

  const setAudio = () => {
    props.changeWithSound()
  }

  return (
    <QuizHeaderWrapper step={step}>
      <QuizHeaderContainer fluid>
        <Row>
          <QuizHeaderCol>
            <QuizHeaderInnerWrapper>
              <QuizHeaderTitle>
                <span>podejmij wyzwanie</span>
                <br /> <span>quiz</span>
              </QuizHeaderTitle>
              <div>
                {step === 5 && (
                  <QuizHeaderTimer>
                    <span>{timeLeft}</span>
                  </QuizHeaderTimer>
                )}
                {step === 9 && (
                  <QuizHeaderGameFinish>
                    <span>wyjdź z gry</span>
                    <a href={withPrefix('/')}>
                      <div className="gameFinishIcon" />
                    </a>
                  </QuizHeaderGameFinish>
                )}
              </div>
            </QuizHeaderInnerWrapper>

            <QuizHeaderBottomIconsWrapper step={step}>
              <QuizHeaderFinishLink href={withPrefix('/')}>
                <span className="wrapper">
                  <span>{'<'} WYJŚCIE Z GRY</span>
                </span>
              </QuizHeaderFinishLink>
              <QuizHeaderSpeaker
                sound={sound}
                step={step}
                onClick={() => setAudio()}
              />
            </QuizHeaderBottomIconsWrapper>

            {step === 5 && (
              <>
                <QuizHeaderScores>
                  <span>{score}</span>&nbsp;{getPointsWord(score)}
                </QuizHeaderScores>
              </>
            )}
          </QuizHeaderCol>
        </Row>
      </QuizHeaderContainer>
    </QuizHeaderWrapper>
  )
}

export default QuizHeader
