import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { getPointsWord } from './quiz-header'
import { dlQuizEvent } from '../events'

import quizFinalScoreTitleBG from '../../images/quiz/quiz_finish_score_title_bg.svg'

const QuizFinalScoreWrapper = styled.div`
  padding-bottom: 50px;
  @media (min-width: 1200px) {
    margin-top: -10%;
  }
`

const QuizFinalScoreTitle = styled.h2`
  position: relative;
  background-image: url(${quizFinalScoreTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  line-height: 0.9;
  /* padding-top: 360px; */
  padding-top: 35px;
  padding-left: 230px;
  padding-bottom: 180px;
  margin-bottom: -30px;
  z-index: 20;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 42px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 40px;
    margin-bottom: -30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 34px;
    margin-bottom: -30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
    padding-top: 40px;
    padding-left: 220px;
    margin-bottom: -40px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    padding-top: 20px;
    padding-left: 130px;
    padding-bottom: 95px;
    margin-bottom: -35px;
  }
`

const QuizFinalScoreButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
  ${(props) =>
    props.last &&
    `
    margin-top: 30px;
  `}
  @media (max-width: 575px) {
    ${(props) =>
      props.last &&
      `
      margin-top: 15px;
    `}
  }
`

const QuizFinalScoreDescWrapper = styled.div`
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 50px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 50px;
  }
  @media (max-width: 575px) {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 50px;
  }
`

const QuizFinalScoreDesc = styled.p`
  background-color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 82px;
  font-weight: 700;
  text-align: center;
  padding-top: 8%;
  padding-bottom: 8%;
  margin-bottom: 0;
  border-radius: 82px;
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 70px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 60px;
  }
  @media (max-width: 575px) {
    font-size: 40px;
    border-radius: 40px;
  }
  span {
    color: #4790dd;
  }
`

const QuizFinalScoreButton = styled(QuizButton)``

const QuizFinalScore = ({
  score,
  maxNumber,
  changeStep,
  buttonText,
  isLoading,
  withSound,
  isInvite,
}) => {
  const playFinal = new Howl({
    src: withPrefix(
      isInvite
        ? '/sounds/zobacz-ile-punktow-zdobyles.mp3'
        : '/sounds/BNP_Quiz_zobacz_ile_pkt_zdobyles.mp3'
    ),
  })
  const playButton = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_kliknij_zmierz_sie.mp3'),
  })

  useEffect(() => {
    if (withSound) {
      playFinal.play()
      playFinal.on('end', () => {
        setTimeout(() => {
          if (!isInvite) {
            playButton.play()
          }
        }, 1000)
      })
    }
  }, [])

  return (
    <QuizFinalScoreWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizFinalScoreTitle>
              odpowiedziałeś
              <br />
              poprawnie na
              <br />
              <span>{Math.floor(score / 2)}</span>/<span>{maxNumber}</span>{' '}
              pytań
            </QuizFinalScoreTitle>
            <QuizFinalScoreDescWrapper>
              <QuizFinalScoreDesc>
                zdobyłeś <span>{score}</span> {getPointsWord(score)}!
              </QuizFinalScoreDesc>
            </QuizFinalScoreDescWrapper>
          </Col>
          {!isLoading && (
            <>
              <QuizFinalScoreButtonWrapper xs={12}>
                <QuizFinalScoreButton
                  score
                  onClick={() => {
                    dlQuizEvent({
                      action: `Kliknięcie w button - ${
                        score > 0
                          ? buttonText.toUpperCase()
                          : 'SPRÓBUJ JESZCZE RAZ'
                      }`,
                      label: 'Quiz: Wyniki',
                    })

                    if (score > 0) {
                      changeStep()
                    } else {
                      window.location.reload()
                    }
                  }}
                  id="quiz-final-score-btn-friend"
                >
                  {score > 0 ? buttonText : 'Spróbuj jeszcze raz'}
                </QuizFinalScoreButton>
              </QuizFinalScoreButtonWrapper>
              {score > 0 && (
                <QuizFinalScoreButtonWrapper last xs={12}>
                  <QuizFinalScoreButton
                    score
                    onClick={() => {
                      dlQuizEvent({
                        action: 'Kliknięcie w button - ZAGRAJ JESZCZE RAZ',
                        label: 'Quiz: Wyniki',
                      })
                      window.location.reload()
                    }}
                    id="quiz-final-score-btn-reload"
                  >
                    {'Zagraj jeszcze raz'}
                  </QuizFinalScoreButton>
                </QuizFinalScoreButtonWrapper>
              )}
            </>
          )}
        </Row>
      </Container>
    </QuizFinalScoreWrapper>
  )
}

export default QuizFinalScore
