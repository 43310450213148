import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { getPointsWord } from './quiz-header'
import { dlQuizEvent } from '../events'

import quizFinalScoreTitleBG from '../../images/quiz/quiz_finish_score_title_bg.svg'
import quizCupIcon from '../../images/quiz/cup_icon.svg'

const QuizCollectiveScoreWrapper = styled.div`
  text-align: center;
`

const QuizCollectiveScoreTitle = styled.h2`
  position: relative;
  background-image: url(${quizFinalScoreTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  line-height: 0.9;
  /* padding-top: 360px; */
  padding-top: 35px;
  padding-left: 230px;
  padding-bottom: 180px;
  margin-bottom: -30px;
  z-index: 20;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: -30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: -30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 46px;
    padding-top: 50px;
    padding-left: 220px;
    margin-bottom: -50px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    padding-top: 20px;
    padding-left: 130px;
    padding-bottom: 95px;
    margin-bottom: -35px;
  }
`

const QuizCollectiveScoreButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizCollectiveScoreDescWrapper = styled.div`
  display: inline-block;
  padding-left: 4%;
  padding-right: 4%;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 2%;
    padding-right: 2%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 1%;
    padding-right: 1%;
  }
  @media (max-width: 575px) {
    padding-left: 1%;
    padding-right: 1%;
  }
`

const QuizCollectiveScoreDesc = styled.div`
  display: inline-block;
  background-color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 74px;
  font-weight: 700;
  text-align: center;
  padding-top: 8%;
  /* padding-right: 8%; */
  padding-bottom: 8%;
  margin-bottom: 0;
  border-radius: 82px;
  @media (min-width: 1200px) and (max-width: 1600px) {
    font-size: 70px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 64px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 50px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 34px;
  }
  @media (max-width: 575px) {
    font-size: 23px;
    border-radius: 40px;
    padding-top: 12%;
    /* padding-right: 22px; */
  }
  span {
    color: #4790dd;
    font-weight: 700;
  }
  ul {
    position: relative;
    display: inline-block;
    width: 100%;
    list-style-type: none;
    text-align: right;
    padding-left: 150px;
    /* margin-bottom: 0; */
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-left: 130px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-left: 110px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding-left: 100px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      padding-left: 90px;
    }
    @media (max-width: 575px) {
      padding-left: 60px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 30px;
      left: 40px;
      display: block;
      width: 80px;
      height: 100px;
      background-image: url(${quizCupIcon});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      @media (min-width: 992px) and (max-width: 1199px) {
        top: 25px;
        left: 30px;
        width: 60px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        top: 25px;
        left: 30px;
        width: 50px;
      }
      @media (min-width: 576px) and (max-width: 767px) {
        top: 20px;
        left: 30px;
        width: 40px;
      }
      @media (max-width: 575px) {
        top: 12px;
        left: 20px;
        width: 32px;
      }
    }
    li {
      white-space: nowrap;
      padding-right: 80px;
      @media (min-width: 576px) and (max-width: 767px) {
        padding-right: 40px;
      }
      @media (max-width: 575px) {
        padding-right: 25px;
      }
    }
  }
`

const QuizCollectiveScoreButton = styled(QuizButton)`
  transform: translateY(-50%);
  @media (min-width: 576px) {
    padding-top: 0.775rem;
    padding-bottom: 1.2rem;
  }
  @media (max-width: 575px) {
    padding-left: 22px;
    padding-right: 22px;
  }
`

const QuizCollectiveScore = ({ invitationData, changeStep, withSound }) => {
  const playFinal = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_oto_wasze_wyniki.mp3'),
  })
  const playButton = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_kliknij_by_zagrac_jeszcze_raz.mp3'),
  })

  useEffect(() => {
    if (withSound) {
      playFinal.play()
      playFinal.on('end', () => {
        setTimeout(() => {
          playButton.play()
        }, 1000)
      })
    }
  }, [])

  return (
    <QuizCollectiveScoreWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizCollectiveScoreTitle>
              oto wasze
              <br /> wyniki
            </QuizCollectiveScoreTitle>
            <QuizCollectiveScoreDescWrapper>
              <QuizCollectiveScoreDesc>
                <ul>
                  {invitationData.players.map((player) => (
                    <li>
                      {player.name} ........... {player.score < 10 && '\u00A0'}
                      <span>{player.score}</span> {getPointsWord(player.score)}
                    </li>
                  ))}
                </ul>
              </QuizCollectiveScoreDesc>
            </QuizCollectiveScoreDescWrapper>
          </Col>
          <QuizCollectiveScoreButtonWrapper xs={12}>
            <QuizCollectiveScoreButton
              next
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - ZAGRAJ JESZCZE RAZ',
                  label: 'Quiz: Wasze wyniki',
                })
                changeStep()
              }}
              id="quiz-collective-score-btn"
            >
              zagraj jeszcze raz
            </QuizCollectiveScoreButton>
          </QuizCollectiveScoreButtonWrapper>
        </Row>
      </Container>
    </QuizCollectiveScoreWrapper>
  )
}

export default QuizCollectiveScore
