import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizBoyBG from '../../images/quiz/quiz_boy.svg'
import quizGirlBG from '../../images/quiz/quiz_girl.svg'
import quizCloudBG from '../../images/quiz/quiz_first_start_cloud.svg'

const QuizStartSoundWrapper = styled.div`
  padding-bottom: 50px;
`

const QuizStartSoundButtonWrapper = styled(Col)`
  position: relative;
  display: flex;
  justify-content: center;
`

const QuizStartSoundPerson = styled.div`
  position: absolute;
  display: block;
  width: 300px;
  height: 300px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-top: 30px;
  transform: translateY(-50%);
  @media (min-width: 576px) and (max-width: 767px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 575px) {
    width: 120px;
    height: 120px;
  }
`

const QuizStartSoundPersonBoy = styled(QuizStartSoundPerson)`
  left: 0;
  background-image: url(${quizBoyBG});
  background-position-x: left;
`

const QuizStartSoundPersonGirl = styled(QuizStartSoundPerson)`
  right: 0;
  background-image: url(${quizGirlBG});
  background-position-x: right;
`

const QuizStartSoundPersonCloud = styled.p`
  position: absolute;
  left: 82px;
  display: flex;
  width: 400px;
  height: 185px;
  align-items: center;
  background-image: url(${quizCloudBG});
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 36px;
  font-weight: 700;
  line-height: 0.9;
  text-align: center;
  margin-bottom: 0;
  transform: translateY(-65%);
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 42px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 40px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 34px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    left: 37px;
    width: 330px;
    height: 155px;
    font-size: 28px;
    transform: translateY(-70%);
  }
  @media (max-width: 575px) {
    left: 15px;
    width: 230px;
    height: 110px;
    font-size: 22px;
    transform: translateY(-70%);
  }
  span {
    display: block;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: -30px;
    @media (max-width: 575px) {
      margin-top: -25px;
    }
  }
`

const QuizStartSoundButton = styled(QuizButton)`
  @media (max-width: 575px) {
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 340px) {
    padding-left: 25px;
    padding-right: 25px;
  }
`

const QuizStartSound = (props) => {
  const { sound } = props
  const initialAudio = new Howl({
    src: withPrefix('/sounds/1-second-of-silence.mp3'),
  })

  const setAudio = () => {
    props.changeStep()
  }

  useEffect(() => {
    initialAudio.play()
  }, [sound])

  return (
    <QuizStartSoundWrapper>
      <Container>
        <Row>
          <Col xs={12}></Col>
          <QuizStartSoundButtonWrapper xs={12}>
            <QuizStartSoundButton
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - START',
                  label: 'Quiz: Start aplikacji',
                })
                setAudio(true)
              }}
              easy
              id="quiz-first-start-button"
            >
              start
            </QuizStartSoundButton>
            <QuizStartSoundPersonBoy>
              <QuizStartSoundPersonCloud>
                <span>
                  Podejmij wyzwanie!
                  <br />
                  Kliknij start, aby rozpocząć.
                </span>
              </QuizStartSoundPersonCloud>
            </QuizStartSoundPersonBoy>
            <QuizStartSoundPersonGirl />
          </QuizStartSoundButtonWrapper>
        </Row>
      </Container>
    </QuizStartSoundWrapper>
  )
}

export default QuizStartSound
