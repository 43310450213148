import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizDifficultyTitleBG from '../../images/quiz/quiz_difficulty_title_bg.svg'

const QuizDifficultyWrapper = styled.div`
  padding-bottom: 50px;
`

const QuizDifficultyTitle = styled.h2`
  background-image: url(${quizDifficultyTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: transparent;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-weight: 700;
  text-align: center;
  padding-top: 360px;
  margin-bottom: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: calc(40.5% - 2rem);
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-top: calc(45.5% - 2rem);
    margin-bottom: 20px;
  }
`

const QuizDifficultyButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizDifficultyButton = styled(QuizButton)``

const QuizDifficulty = (props) => {
  const initialAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_wybierz_poziom.mp3'),
  })
  const easyAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_wybrales_poziom_latwy.mp3'),
  })
  const hardAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_wybrales_poziom_trudny.mp3'),
  })

  useEffect(() => {
    if (props.withSound) {
      initialAudio.play()
    }
  }, [])

  const setDifficulty = (difficulty) => {
    props.changeDifficulty(difficulty)
    props.changeStep()
  }
  return (
    <QuizDifficultyWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizDifficultyTitle>Wybierz poziom gry</QuizDifficultyTitle>
          </Col>
          <QuizDifficultyButtonWrapper xs={12}>
            <QuizDifficultyButton
              onClick={() => {
                if (props.withSound) {
                  easyAudio.play()
                }
                dlQuizEvent({
                  action: 'Kliknięcie w button - ŁATWY',
                  label: 'Quiz: Wybór poziomu trudności',
                })
                setDifficulty('easy')
              }}
              left
              easy
              id="quiz-difficulty-btn-easy"
            >
              Łatwy
            </QuizDifficultyButton>
            <QuizDifficultyButton
              onClick={() => {
                if (props.withSound) {
                  hardAudio.play()
                }
                dlQuizEvent({
                  action: 'Kliknięcie w button - TRUDNY',
                  label: 'Quiz: Wybór poziomu trudności',
                })
                setDifficulty('hard')
              }}
              right
              hard
              id="quiz-difficulty-btn-hard"
            >
              Trudny
            </QuizDifficultyButton>
          </QuizDifficultyButtonWrapper>
        </Row>
      </Container>
    </QuizDifficultyWrapper>
  )
}

export default QuizDifficulty
