import axios from 'axios'

const BaseUrl =
  'https://5c0uapzf8j.execute-api.eu-central-1.amazonaws.com/production/'

const b64EncodeUnicode = (str) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(
      match,
      p1
    ) {
      return String.fromCharCode('0x' + p1)
    })
  )
}

export const makeSignature = (name) => {
  return b64EncodeUnicode(name.toUpperCase() + name.toLowerCase())
    .toString('base64')
    .replace(/[^a-z']/g, '')
    .toUpperCase()
}

export const getPlayer = (id) => {
  return axios.get(`${BaseUrl}get?id=${id}`)
}

export const invitePlayers = (name, score, difficulty) => {
  const sig = makeSignature(name)
  return axios.post(`${BaseUrl}invite`, {
    name: name,
    sig: sig,
    difficulty: difficulty,
    score: score,
  })
}

export const updatePlayers = (id, name, score, playerPosition) => {
  let url = `${BaseUrl}update?id=${id}`
  if (playerPosition) {
    url += `&position=${playerPosition}`
  }

  const sig = makeSignature(name)
  return axios.post(url, {
    name: name,
    sig: sig,
    score: score,
  })
}
