import React, { useEffect, useState } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizInputNameTitleBG from '../../images/quiz/quiz_input_name_title_bg.svg'
import quizInput from '../../images/quiz/quiz_input_bg.svg'

const QuizInputNameWrapper = styled.div``

const QuizInputNameTitle = styled.h2`
  background-image: url(${quizInputNameTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: transparent;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-weight: 700;
  text-align: center;
  padding-top: calc(23.5% - 2rem);
  margin-bottom: 30px;
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: calc(28.5% - 2rem);
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    padding-top: calc(47.5% - 2rem);
    margin-bottom: 20px;
  }
`

const QuizInputNameInput = styled(Input)`
  max-width: 670px;
  height: 155px;
  background-color: transparent !important;
  background-image: url(${quizInput});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: ${(props) => props.theme.colorBlack};
  font-family: ${(props) => props.theme.fontVT323};
  font-size: 76px;
  text-align: center;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 620px;
    height: 150px;
    font-size: 68px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: 540px;
    height: 140px;
    font-size: 58px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    max-width: 440px;
    height: 105px;
    font-size: 48px;
  }
  @media (max-width: 575px) {
    max-width: 280px;
    height: 65px;
    font-size: 30px;
  }
`

const QuizInputNameButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizInputNameButton = styled(QuizButton)`
  &:disabled {
    background-color: #0fa169 !important;
    border-color: ${(props) => props.theme.colorWhite}!important;
    opacity: 1 !important;
  }
`

const QuizInputName = (props) => {
  const [value, setValue] = useState('')
  const initialAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_wpisz_imie.mp3'),
  })

  useEffect(() => {
    if (props.withSound) {
      setTimeout(() => {
        initialAudio.play()
      }, 2000)
    }
  }, [])

  const changeValue = (event) => {
    const name = event.target.value
    setValue(name.trim())
  }

  const setName = () => {
    props.setUserName(value)
    props.changeStep()
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setName()
    initialAudio.stop()
  }

  return (
    <QuizInputNameWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizInputNameTitle>jak masz na imię?</QuizInputNameTitle>
          </Col>
          <Col xs={12}>
            <Form onSubmit={handleFormSubmit}>
              <FormGroup>
                <QuizInputNameInput
                  type="text"
                  name="name"
                  id="name"
                  placeholder=""
                  value={value}
                  onChange={changeValue}
                  autoComplete="off"
                />
              </FormGroup>
            </Form>
          </Col>
          <QuizInputNameButtonWrapper xs={12}>
            <QuizInputNameButton
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - ZACZYNAMY',
                  label: 'Quiz: Wybór imienia',
                })
                setName()
              }}
              disabled={value.length === 0}
              start
              id="quiz-input-name-btn"
            >
              zaczynamy
            </QuizInputNameButton>
          </QuizInputNameButtonWrapper>
        </Row>
      </Container>
    </QuizInputNameWrapper>
  )
}

export default QuizInputName
