import React, { useEffect, useState } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { randomSoundNumber } from './lib/random-sound-number'
import {
  QuizButton,
  quizDescMixin,
  quizQuestionBordersWrapperMixin,
} from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizQuestionGirlBoyBG from '../../images/quiz/quiz_question_girl_boy_bg.svg'
import quizQuestionGirlBoyTrueBG from '../../images/quiz/quiz_question_girl_boy_true_bg.svg'
import quizQuestionGirlBoyFalseBG from '../../images/quiz/quiz_question_girl_boy_false_bg.svg'
import quizQuestionGirlBoyBGMobile from '../../images/quiz/quiz_question_girl_boy_bg_mobile.svg'
import quizQuestionGirlBoyTrueBGMobile from '../../images/quiz/quiz_question_girl_boy_true_bg_mobile.svg'
import quizQuestionGirlBoyFalseBGMobile from '../../images/quiz/quiz_question_girl_boy_false_bg_mobile.svg'

const QuizQuestionMultipleWrapper = styled.div`
  @media (max-width: 400px) and (max-height: 599px) {
    margin-top: 100px;
  }
`

const QuizQuestionColDescWrapper = styled(Col)`
  position: relative;
  text-align: center;
  padding-left: 12%;
  padding-right: 12%;
  @media (max-width: 575px) {
    padding-left: 6%;
    padding-right: 6%;
  }
`

const QuizQuestionDescWrapper = styled.div`
  text-align: center;
`

const QuizQuestionStatusBG = styled.div`
  position: absolute;
  top: -100%;
  right: 89%;
  bottom: -10%;
  width: 270px;
  background-image: url(${quizQuestionGirlBoyBG});
  ${(props) =>
    props.answered &&
    props.right &&
    `
    background-image: url(${quizQuestionGirlBoyTrueBG});
  `}
  ${(props) =>
    props.answered &&
    !props.right &&
    `
    background-image: url(${quizQuestionGirlBoyFalseBG});
  `}
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  pointer-events: none;
  z-index: 1000;
  @media (max-width: 575px) {
    width: 160px;
    height: 400px;
    top: auto;
    left: 10px;
    right: auto;
    bottom: 95%;
    background-image: url(${quizQuestionGirlBoyBGMobile});
    ${(props) =>
      props.answered &&
      props.right &&
      `
      background-image: url(${quizQuestionGirlBoyTrueBGMobile});
    `}
    ${(props) =>
      props.answered &&
      !props.right &&
      `
      background-image: url(${quizQuestionGirlBoyFalseBGMobile});
    `}
  }
`

const QuizQuestionDesc = styled.div`
  ${quizDescMixin}
  ${quizQuestionBordersWrapperMixin}
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.fontGothamCondensed};
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 30px;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
  }
`

const QuizQuestionTitleList = styled.p`
  font-family: ${(props) => props.theme.fontGothamCondensed};
  font-size: 36px;
  padding-bottom: 40px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 26px;
    padding-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    font-size: 24px;
    padding-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    padding-bottom: 15px;
  }
`

const QuizQuestionList = styled.ul`
  display: inline-block;
  list-style-type: upper-latin;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 32px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 32px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 28px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
  }
`

const QuizQuestionListItem = styled.li`
  padding-left: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-bottom: 10px;
  }
  @media (max-width: 575px) {
    padding-left: 5px;
    margin-bottom: 10px;
  }
  &:hover {
    ${(props) =>
      !props.answered &&
      `
        color: #4790dd;
      `}
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &.active {
    color: #4790dd;
  }
  ${(props) =>
    props.answered &&
    props.verity &&
    `
      color: #00b380!important;
    `}
  ${(props) =>
    props.answered &&
    !props.verity &&
    `
      color: #c31841!important;
    `}
`

const QuizQuestionMultipleButtonWrapper = styled(Col)`
  text-align: center;
`

const QuizQuestionMultipleButton = styled(QuizButton)`
  transition: ease 0.2s all;
  transform: translateY(-20%);

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: 575px) {
    transform: translateY(-35%);
  }
`

const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

const QuizQuestionMultiple = ({
  question,
  changeQuestion,
  onAnswer,
  withSound,
  questionNumber,
}) => {
  const [isAnswered, setIsAnswered] = useState(false)
  const [isRight, setIsRight] = useState(false)
  const [checkedAnswers, setCheckedAnswers] = useState([])

  const playTrue = new Howl({
    src: withPrefix(
      `/sounds/BNP_Quiz_dobra_odpowiedz_0${randomSoundNumber(
        questionNumber,
        true
      )}.mp3`
    ),
  })
  const playFalse = new Howl({
    src: withPrefix(
      `/sounds/BNP_Quiz_zla_odpowiedz_0${randomSoundNumber(
        questionNumber,
        false
      )}.mp3`
    ),
  })

  useEffect(() => {
    setIsAnswered(false)
    setIsRight(false)
    setCheckedAnswers([])
  }, [question])

  const handleAnswer = () => {
    if (isAnswered) {
      dlQuizEvent({
        action: 'Kliknięcie w button - DALEJ (Przejście do kolejnego pytania)',
        label: `Quiz: Gameplay (Pytanie ${questionNumber})`,
      })
      changeQuestion(isRight)
      return
    }

    setIsAnswered(true)
    if (arraysEqual(question.answer, checkedAnswers)) {
      if (withSound) {
        playTrue.play()
      }
      setIsRight(true)
    } else {
      if (withSound) {
        playFalse.play()
      }
      setIsRight(false)
    }

    dlQuizEvent({
      action: 'Kliknięcie w button - DALEJ (Wybór odpowiedzi)',
      label: `Quiz: Gameplay (Pytanie ${questionNumber})`,
    })
    onAnswer()
  }

  const handleAnswerClick = (answer) => {
    if (!isAnswered) {
      if (checkedAnswers.includes(answer)) {
        setCheckedAnswers([...checkedAnswers.filter((a) => a !== answer)])
      } else {
        setCheckedAnswers([...checkedAnswers, answer])
      }
    }
  }

  return (
    <QuizQuestionMultipleWrapper>
      <Container>
        <Row>
          <QuizQuestionColDescWrapper xs={12}>
            <QuizQuestionStatusBG answered={isAnswered} right={isRight} />
            <QuizQuestionDescWrapper>
              <QuizQuestionDesc>
                <div>
                  <QuizQuestionTitleList>
                    {question.question}
                  </QuizQuestionTitleList>
                  <QuizQuestionList answered={isAnswered}>
                    {question.answers.map((item, idx) => {
                      return (
                        <QuizQuestionListItem
                          key={idx}
                          onClick={() => handleAnswerClick(item)}
                          className={
                            checkedAnswers.includes(item) ? `active` : ``
                          }
                          answered={isAnswered}
                          verity={
                            checkedAnswers && question.answer.includes(item)
                          }
                        >
                          {item}
                        </QuizQuestionListItem>
                      )
                    })}
                  </QuizQuestionList>
                </div>
              </QuizQuestionDesc>
            </QuizQuestionDescWrapper>
          </QuizQuestionColDescWrapper>
          <QuizQuestionMultipleButtonWrapper xs={12}>
            <QuizQuestionMultipleButton
              nextquestion
              onClick={handleAnswer}
              disabled={checkedAnswers.length === 0}
              id="quiz-question-multiple-btn"
            >
              Dalej
            </QuizQuestionMultipleButton>
          </QuizQuestionMultipleButtonWrapper>
        </Row>
      </Container>
    </QuizQuestionMultipleWrapper>
  )
}

export default QuizQuestionMultiple
