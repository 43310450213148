import React, { useEffect, useState } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { randomSoundNumber } from './lib/random-sound-number'
import {
  QuizButton,
  quizDescMixin,
  quizQuestionBordersWrapperMixin,
} from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizQuestionGirlBoyBG from '../../images/quiz/quiz_question_girl_boy_bg.svg'
import quizQuestionGirlBoyTrueBG from '../../images/quiz/quiz_question_girl_boy_true_bg.svg'
import quizQuestionGirlBoyFalseBG from '../../images/quiz/quiz_question_girl_boy_false_bg.svg'
import quizQuestionGirlBoyBGMobile from '../../images/quiz/quiz_question_girl_boy_bg_mobile.svg'
import quizQuestionGirlBoyTrueBGMobile from '../../images/quiz/quiz_question_girl_boy_true_bg_mobile.svg'
import quizQuestionGirlBoyFalseBGMobile from '../../images/quiz/quiz_question_girl_boy_false_bg_mobile.svg'

const QuizQuestionRadioWrapper = styled.div`
  @media (max-width: 400px) and (max-height: 599px) {
    margin-top: 100px;
  }
`

const QuizQuestionColDescWrapper = styled(Col)`
  position: relative;
  text-align: center;
  padding-left: 12%;
  padding-right: 12%;
  @media (max-width: 575px) {
    padding-left: 6%;
    padding-right: 6%;
  }
`

const QuizQuestionDescWrapper = styled.div`
  text-align: center;
`

const QuizQuestionStatusBG = styled.div`
  position: absolute;
  top: -100%;
  right: 89%;
  bottom: -10%;
  width: 270px;
  background-image: url(${quizQuestionGirlBoyBG});
  ${(props) =>
    props.answered &&
    props.right &&
    `
    background-image: url(${quizQuestionGirlBoyTrueBG});
  `}
  ${(props) =>
    props.answered &&
    !props.right &&
    `
    background-image: url(${quizQuestionGirlBoyFalseBG});
  `}
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: contain;
  pointer-events: none;
  z-index: 1000;
  @media (max-width: 575px) {
    width: 160px;
    height: 400px;
    top: auto;
    left: 10px;
    right: auto;
    bottom: 95%;
    background-image: url(${quizQuestionGirlBoyBGMobile});
    ${(props) =>
      props.answered &&
      props.right &&
      `
      background-image: url(${quizQuestionGirlBoyTrueBGMobile});
    `}
    ${(props) =>
      props.answered &&
      !props.right &&
      `
      background-image: url(${quizQuestionGirlBoyFalseBGMobile});
    `}
  }
`

const QuizQuestionDesc = styled.div`
  ${quizDescMixin}
  ${quizQuestionBordersWrapperMixin}
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontGothamCondensed};
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 0;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 30px;
    padding-top: 4%;
    padding-bottom: 4%;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
  }
`

const QuizQuestionRadioButtonWrapper = styled(Col)`
  text-align: center;
`

const QuizQuestionRadioButton = styled(QuizButton)`
  transform: translateY(-20%);
  @media (max-width: 575px) {
    transform: translateY(-35%);
  }
  &:disabled {
    ${(props) =>
      props.true &&
      `
    background-color: #5ca5f2!important;
    border-color: ${props.theme.colorWhite}!important;
  `}
    ${(props) =>
      props.false &&
      `
    background-color: #cac7c7!important;
    border-color: ${props.theme.colorWhite}!important;
  `}
    ${(props) =>
      props.verity &&
      `
      background-color: #00b380!important;
    `}
    ${(props) =>
      !props.verity &&
      `
      background-color: #c31841!important;
    `}
    opacity: 1!important;
  }
`

const QuizQuestionRadioNextButton = styled(QuizButton)`
  transition: ease 0.2s all;

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
`

const QuizQuestionRadio = ({
  question,
  changeQuestion,
  onAnswer,
  withSound,
  questionNumber,
}) => {
  const [isAnswered, setIsAnswered] = useState(false)
  const [isRight, setIsRight] = useState(false)

  const playTrue = new Howl({
    src: withPrefix(
      `/sounds/BNP_Quiz_dobra_odpowiedz_0${randomSoundNumber(
        questionNumber,
        true
      )}.mp3`
    ),
  })
  const playFalse = new Howl({
    src: withPrefix(
      `/sounds/BNP_Quiz_zla_odpowiedz_0${randomSoundNumber(
        questionNumber,
        false
      )}.mp3`
    ),
  })

  useEffect(() => {
    setIsAnswered(false)
    setIsRight(false)
  }, [question])

  const handleAnswer = (correctness) => {
    setIsAnswered(true)

    if (correctness) {
      if (withSound) {
        playTrue.play()
      }
      setIsRight(true)
    } else {
      if (withSound) {
        playFalse.play()
      }
      setIsRight(false)
    }
    onAnswer()
  }

  return (
    <QuizQuestionRadioWrapper>
      <Container>
        <Row>
          <QuizQuestionColDescWrapper xs={12}>
            <QuizQuestionStatusBG answered={isAnswered} right={isRight} />
            <QuizQuestionDescWrapper>
              <QuizQuestionDesc>
                <p>{question.question}</p>
              </QuizQuestionDesc>
            </QuizQuestionDescWrapper>
            <QuizQuestionRadioButton
              left
              true
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - PRAWDA (Wybrór odpowiedzi)',
                  label: `Quiz: Gameplay (Pytanie ${questionNumber})`,
                })
                handleAnswer(question.answer === true)
              }}
              disabled={isAnswered}
              verity={question.answer === true}
              id="quiz-question-bool-btn-true"
            >
              prawda
            </QuizQuestionRadioButton>
            <QuizQuestionRadioButton
              right
              false
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - FAŁSZ (Wybrór odpowiedzi)',
                  label: `Quiz: Gameplay (Pytanie ${questionNumber})`,
                })
                handleAnswer(question.answer === false)
              }}
              disabled={isAnswered}
              verity={question.answer === false}
              id="quiz-question-bool-btn-false"
            >
              fałsz
            </QuizQuestionRadioButton>
          </QuizQuestionColDescWrapper>
          <QuizQuestionRadioButtonWrapper xs={12}>
            <QuizQuestionRadioNextButton
              nextquestion
              onClick={() => {
                dlQuizEvent({
                  action:
                    'Kliknięcie w button - DALEJ (Przejście do kolejnego pytania)',
                  label: `Quiz: Gameplay (Pytanie ${questionNumber})`,
                })
                changeQuestion(isRight)
              }}
              disabled={!isAnswered}
              id="quiz-question-bool-btn"
            >
              dalej
            </QuizQuestionRadioNextButton>
          </QuizQuestionRadioButtonWrapper>
        </Row>
      </Container>
    </QuizQuestionRadioWrapper>
  )
}

export default QuizQuestionRadio
