export const dlEvent = (event) => {
  if (!process.browser) {
    return
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}

export const dlQuizEvent = (event) => {
  return dlEvent({
    event: 'ms-gameevent',
    category: 'Misja samodzielność',
    ...event,
  })
}
