import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizGetInvitationTitleBG from '../../images/quiz/quiz_get_invitation_bg.svg'

const QuizGetInvitationWrapper = styled.div``

const QuizGetInvitationTitle = styled.div`
  position: relative;
  background-image: url(${quizGetInvitationTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 50px;
  font-weight: 700;
  text-align: center;
  line-height: 0.9;
  padding-left: 28%;
  padding-right: 22.5%;
  padding-bottom: 30%;
  z-index: 20;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 46px;
    padding-left: 220px;
  }
  @media (max-width: 575px) {
    font-size: 26px;
    padding-left: 130px;
    padding-bottom: 95px;
  }
  h2 {
    font-family: ${(props) => props.theme.fontAmaticSC};
    font-size: 62px;
    font-weight: 700;
    line-height: 0.9;
    margin-top: -2rem;
    margin-bottom: 0;
    @media (max-width: 575px) {
      font-size: 32px;
    }
  }
  p {
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 40px;
    font-weight: 700;
    @media (max-width: 575px) {
      font-size: 20px;
    }
    span {
      color: #4790dd;
    }
  }
`

const QuizGetInvitationTitleInnerWrapper = styled.div`
  display: flex;
  min-height: 330px;
  align-items: center;
`

const QuizGetInvitationButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizGetInvitationButton = styled(QuizButton)`
  position: relative;
  transform: translateY(-100%);
  z-index: 20;
`

const QuizGetInvitation = ({ userName, changeStep, withSound }) => {
  const playFinal = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_ktos_zaprosil_cie_do_quizu.mp3'),
  })
  const playButton = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_kliknij_start_wyzwanie.mp3'),
  })

  useEffect(() => {
    if (withSound) {
      playFinal.play()
      playFinal.on('end', () => {
        setTimeout(() => {
          playButton.play()
        }, 1000)
      })
    }
  }, [])

  return (
    <QuizGetInvitationWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizGetInvitationTitle>
              <QuizGetInvitationTitleInnerWrapper>
                <div>
                  <h2>cześć</h2>
                  <p>
                    {userName} zaprasza Cię do quizu o bankowaniu. Podejmij
                    wyzwanie, klikając <span>START</span>.
                  </p>
                </div>
              </QuizGetInvitationTitleInnerWrapper>
            </QuizGetInvitationTitle>
          </Col>
          <QuizGetInvitationButtonWrapper xs={12}>
            <QuizGetInvitationButton
              next
              onClick={() => {
                dlQuizEvent({
                  action: 'Kliknięcie w button - START',
                  label: 'Quiz: Zaproszenie do gry',
                })
                changeStep()
              }}
              id="quiz-get-invitation-btn"
            >
              start
            </QuizGetInvitationButton>
          </QuizGetInvitationButtonWrapper>
        </Row>
      </Container>
    </QuizGetInvitationWrapper>
  )
}

export default QuizGetInvitation
