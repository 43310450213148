import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Input } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
  QuizButton,
  quizDescBorderRadiusMixin,
  QuizDesc,
} from './quiz-elements'
import { invitePlayers } from './api'
import { dlQuizEvent } from '../events'

import quizGiveInvitationLinkLeftBG from '../../images/quiz/quiz_give_invitation_link_left.svg'
import quizGiveInvitationLinkRightBG from '../../images/quiz/quiz_give_invitation_link_right.svg'

const LoadingText = styled.p`
  animation: 5s loadingTextAnimation infinite;
  color: #fff;
  font-size: 2em;
  text-align: center;

  @keyframes loadingTextAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
`

const QuizGiveInvitationWrapper = styled.div`
  @media (min-width: 1200px) {
    margin-top: -10%;
  }
  @media (min-width: 1200px) and (max-width: 1599px) {
    margin-top: -20%;
  }
`

const QuizGiveInvitationTitle = styled.h2`
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontAmaticSC};
  font-size: 76px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 40px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 60px;
    margin-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 50px;
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    font-size: 41px;
    margin-bottom: 15px;
  }
  @media (max-width: 340px) {
    font-size: 39px;
  }
`

const QuizGiveInvitationButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizGiveInvitationDescWrapper = styled.div`
  padding-left: 8%;
  padding-right: 8%;
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 6%;
    padding-right: 6%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 3%;
    padding-right: 3%;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-left: 1%;
    padding-right: 1%;
  }
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const QuizGiveInvitationDescStartWrapper = styled(
  QuizGiveInvitationDescWrapper
)``

const QuizGiveInvitationDescLinkWrapper = styled(
  QuizGiveInvitationDescWrapper
)``

const QuizGiveInvitationDesc = styled(QuizDesc)`
  ${quizDescBorderRadiusMixin}
  background-color: ${(props) => props.theme.colorWhite};
  text-align: center;
  margin-bottom: 0;
  @media (min-width: 768px) and (max-width: 991px) {
  }
  @media (min-width: 576px) and (max-width: 767px) {
  }
  @media (max-width: 575px) {
    padding-bottom: 12%;
  }
`

const QuizGiveInvitationDescStart = styled(QuizGiveInvitationDesc)``

const QuizGiveInvitationDescLink = styled(QuizGiveInvitationDesc)`
  .copyLink {
    position: relative;
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 28px;
    font-weight: 700;
    margin-top: 40px;
    border-top: 6px solid #e5e5e5;
    border-bottom: 6px solid #e5e5e5;
    @media (min-width: 992px) and (max-width: 1199px) {
    }
    @media (min-width: 768px) and (max-width: 991px) {
    }
    @media (min-width: 576px) and (max-width: 767px) {
    }
    @media (max-width: 575px) {
      font-size: 16px;
      margin-top: 20px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -6px;
      bottom: -6px;
      display: block;
      width: 30px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 100%;
      z-index: 20;
      @media (min-width: 992px) and (max-width: 1199px) {
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
        width: 20px;
      }
    }
    &:before {
      left: -8px;
      background-image: url(${quizGiveInvitationLinkLeftBG});
      @media (max-width: 575px) {
        left: -4px;
      }
    }
    &:after {
      right: -8px;
      background-image: url(${quizGiveInvitationLinkRightBG});
      @media (max-width: 575px) {
        right: -4px;
      }
    }
    .copyLinkWrapper {
      display: block;
      width: 100%;
      height: 60px;
      font-family: ${(props) => props.theme.fontBNPPSansCondensed};
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      line-height: 0;
      padding-top: 30px;
      padding-bottom: 60px;
      padding-left: 20px;
      padding-right: 20px;
      border: none;
      box-shadow: none;
      @media (max-width: 575px) {
        height: 46px;
        font-size: 18px;
        padding-top: 8px;
        padding-bottom: 30px;
      }
    }
  }
`

const QuizGiveInvitationButton = styled(QuizButton)`
  ${(props) =>
    props.copied &&
    `
    background-color: #2dc68b!important;
  `}
  transform: translateY(-50%);
`

const QuizGiveInvitation = ({ name, score, difficulty, changeStep }) => {
  const [value, setValue] = useState()
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    invitePlayers(name, score, difficulty).then((resp) => {
      if (resp.status !== 200) {
        alert('Coś poszło nie tak. W tej chwili nie mogę przygotować linka.')
        return
      }
      setValue(
        `https://www.bnpparibas.pl/misjasamodzielnosc/quiz?id=${resp.data.id}`
      )
    })
  }, [])

  const handleNewGameClick = () => {
    dlQuizEvent({
      action: 'Kliknięcie w button - START',
      label: 'Quiz: Zmierz się z bliskimi',
    })
    window.location.href = value
  }

  return (
    <QuizGiveInvitationWrapper>
      <Container>
        {value && (
          <Row>
            <Col xs={12}>
              <QuizGiveInvitationTitle>
                zmierz się z bliskimi
              </QuizGiveInvitationTitle>
            </Col>

            <Col xs={12}>
              <QuizGiveInvitationDescStartWrapper>
                <QuizGiveInvitationDescStart>
                  <p>
                    Chcesz zaprosić osobę do gry na tym
                    <br /> samym urządzeniu? Kliknij START.
                  </p>
                </QuizGiveInvitationDescStart>
              </QuizGiveInvitationDescStartWrapper>
            </Col>
            <QuizGiveInvitationButtonWrapper xs={12}>
              <QuizGiveInvitationButton
                next
                onClick={handleNewGameClick}
                id="quiz-get-invitation-btn-start"
              >
                start
              </QuizGiveInvitationButton>
            </QuizGiveInvitationButtonWrapper>

            <Col xs={12}>
              <QuizGiveInvitationDescLinkWrapper>
                <QuizGiveInvitationDescLink>
                  <p>
                    Chcesz zaprosić osobę do gry przez internet?
                    <br /> Skopiuj link i wyślij, wklejając go do wiadomości.
                  </p>
                  <div className="copyLink">
                    <div>
                      <Input
                        type="text"
                        className="copyLinkWrapper"
                        value={value}
                      />
                    </div>
                  </div>
                </QuizGiveInvitationDescLink>
              </QuizGiveInvitationDescLinkWrapper>
            </Col>
            <QuizGiveInvitationButtonWrapper xs={12}>
              <CopyToClipboard
                text={value}
                onCopy={() => {
                  dlQuizEvent({
                    action: 'Kliknięcie w button - KOPIUJ LINK',
                    label: 'Quiz: Zmierz się z bliskimi',
                  })
                  setCopied(true)
                }}
              >
                <QuizGiveInvitationButton
                  copied={copied}
                  score
                  id="quiz-give-invitation-btn-link"
                >
                  kopiuj link
                </QuizGiveInvitationButton>
              </CopyToClipboard>
            </QuizGiveInvitationButtonWrapper>
          </Row>
        )}

        {!value && (
          <Row>
            <Col xs={12}>
              <LoadingText>Poczekaj chwilkę...</LoadingText>
            </Col>
          </Row>
        )}
      </Container>
    </QuizGiveInvitationWrapper>
  )
}

export default QuizGiveInvitation
