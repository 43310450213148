export const QUESTIONS_EASY = [
  {
    type: `choice`,
    question: `Dziś pieniądze inaczej to…`,
    answers: ['hajs', 'sieczka', 'zboże'],
    answer: ['hajs'],
  },
  {
    type: `bool`,
    question: `Nazwa pieniądza w danym państwie to „waluta”.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Choć bierzemy pieniądze z bankomatu, to w rzeczywistości są one:`,
    answers: [
      'przedmiotem do zabawy',
      'nagrodą za wykonaną pracę',
      'kolorowym papierkiem',
    ],
    answer: ['nagrodą za wykonaną pracę'],
  },
  {
    type: `choice`,
    question: `Kto pierwszy na świecie zaczął wytwarzać pieniądze?`,
    answers: ['Rzymianie', 'Fenicjanie'],
    answer: ['Fenicjanie'],
  },
  {
    type: `choice`,
    question: `Kto może legalnie emitować pieniądze?`,
    answers: ['władca kraju', 'banki narodowe'],
    answer: ['banki narodowe'],
  },
  {
    type: `choice`,
    question: `Nazwa „bank” pochodzi od włoskiego słowa „banco”, czyli:`,
    answers: ['sklepik', 'ławka'],
    answer: ['ławka'],
  },
  {
    type: `choice`,
    question: `Jak się nazywa karta magnetyczna służąca do płacenia:`,
    answers: [
      'karta płatnicza',
      'karta kredytowa',
      'obie odpowiedzi są poprawne',
    ],
    answer: ['obie odpowiedzi są poprawne'],
  },
  {
    type: `choice`,
    question: `Mennica Polska znajduje się w:`,
    answers: ['Krakowie', 'Gdańsku', 'Warszawie'],
    answer: ['Warszawie'],
  },
  {
    type: `choice`,
    question: `Przodkowie pieniądza to metale. Jakie?`,
    answers: ['żelazo, złoto i srebro', 'brąz, srebro i złoto'],
    answer: ['brąz, srebro i złoto'],
  },
  {
    type: `bool`,
    question: `Pierwsi bankierzy to złotnicy, u których zostawiano specjalne kwity depozytowe. Stąd wzięły się banknoty.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Na polskim banknocie widnieje wizerunek pierwszej polskiej monety. Co to za moneta?`,
    answers: ['grosz', 'denar'],
    answer: ['denar'],
  },
  {
    type: `bool`,
    question: `Pierwsze banknoty na świecie pojawiły się w Chinach w VII wieku.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Wytwórnia Papierów Wartościowych to miejsce, gdzie…`,
    answers: ['wybija się monety', 'drukuje się banknoty'],
    answer: ['drukuje się banknoty'],
  },
  {
    type: `bool`,
    question: `Stare, zniszczone i rozdrobnione banknoty są dodatkiem do opału.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Skarbonka w formie świnki pochodzi od słowa „pygg”, oznaczającego glinę, z której powstawały naczynia na pieniądze.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `W świetle UV na banknocie 10 zł z portretem Mieszka I pojawiają się drugie wąsy.`,
    answer: false,
  },
  {
    type: `choice`,
    question: `W przeszłości nazywano ją białym złotem z powodu wysokiej wartości.`,
    answers: ['woda', 'sól', 'kasza manna'],
    answer: ['sól'],
  },
  {
    type: `bool`,
    question: `To ważne, aby dbać o swoje pieniądze i być rozsądnym.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Gdy mamy swoje własne pieniądze, to:`,
    answers: [
      'planujemy, na co je wydajemy',
      'oszczędzamy',
      'obie odpowiedzi są prawidłowe',
    ],
    answer: ['obie odpowiedzi są prawidłowe'],
  },
  {
    type: `choice`,
    question: `Osoba, która dba o swoje pieniądze: `,
    answers: [
      'wydaje na wszystko, czego akurat teraz potrzebuje',
      'wydaje pieniądze tak, aby na koniec miesiąca było zero na koncie',
      'wydaje pieniądze rozsądnie i jeszcze zostają oszczędności',
    ],
    answer: ['wydaje pieniądze rozsądnie i jeszcze zostają oszczędności'],
  },
  {
    type: `choice`,
    question: `Najlepsze sposoby oszczędzania pieniędzy to:`,
    answers: [
      'konto oszczędnościowe lub lokata w banku',
      'skarpeta w szafie',
      'świnka skarbonka',
    ],
    answer: ['konto oszczędnościowe lub lokata w banku'],
  },
  {
    type: `choice`,
    question: `Najlepiej oszczędzać pieniądze, ale gdy ich zabraknie, a bardzo ich potrzebujemy, żeby kupić coś ważnego, to:`,
    answers: ['pożyczamy z banku', 'czekamy, aż pieniądze urosną na drzewach'],
    answer: ['pożyczamy z banku'],
  },
  {
    type: `choice`,
    question: `Co warto robić, będąc dzieckiem, żeby w przyszłości mieć dobrą pracę i swoje pieniądze?`,
    answers: [
      'jeść śniadania i być zdrowym',
      'dobrze się uczyć',
      'obie odpowiedzi są prawidłowe',
    ],
    answer: ['obie odpowiedzi są prawidłowe'],
  },
  {
    type: `choice`,
    question: `Karta płatnicza służy do:`,
    answers: [
      'płacenia w sklepie',
      'wypłacania pieniędzy w bankomacie',
      'obie odpowiedzi są prawidłowe',
    ],
    answer: ['obie odpowiedzi są prawidłowe'],
  },
  {
    type: `bool`,
    question: `Karta płatnicza jest zabezpieczona numerem PIN.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Żeby zapłacić zbliżeniowo za zakupy w sklepie, potrzebujesz: `,
    answers: [
      'mieć konto, kartę płatniczą i pieniądze na koncie',
      'przybić piątkę ze sprzedawcą w sklepie',
    ],
    answer: ['mieć konto, kartę płatniczą i pieniądze na koncie'],
  },
  {
    type: `choice`,
    question: `Z pustego i Salomon nie naleje. Co przelewa się z konta na konto?`,
    answers: ['wodę', 'colę', 'pieniądze'],
    answer: ['pieniądze'],
  },
  {
    type: `bool`,
    question: `Czy to prawda, że każdą kartą płatniczą dokonasz transakcji internetowej?`,
    answer: false,
  },
  {
    type: `choice`,
    question: `Bankomat to sejf i komputer w jednym. Dostępne są w nim do wypłaty…`,
    answers: ['banknoty i monety', 'tylko banknoty'],
    answer: ['tylko banknoty'],
  },
  {
    type: `bool`,
    question: `Przed wypłatą pieniędzy bankomat kontaktuje się z Twoim bankiem.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `W bankomacie może znajdować się milion złotych.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Alek ma w kieszeni dwa banknoty o łącznej wartości 20 złotych. Jakie to banknoty?`,
    answers: ['10 zł + 10 zł', '5 zł + 15 zł'],
    answer: ['10 zł + 10 zł'],
  },
  {
    type: `bool`,
    question: `Wynagrodzenie to pieniądze, którą dorośli otrzymują za wykonywaną przez siebie pracę.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Konto w banku to specjalne miejsce, na które wpływają pieniądze zarabiane przez dorosłych. `,
    answer: true,
  },
  {
    type: `choice`,
    question: `Co to są finanse osobiste? Finanse osobiste to:`,
    answers: [
      'planowanie, na co wydajemy i na co oszczędzamy nasze pieniądze',
      'pieniądze, które możemy wydać na wszystko, co chcemy, np. na kilogram gumy balonowej',
    ],
    answer: ['planowanie, na co wydajemy i na co oszczędzamy nasze pieniądze'],
  },
  {
    type: `choice`,
    question: `Dlaczego warto stworzyć listę potrzebnych rzeczy do kupienia, zanim pójdziemy na zakupy?`,
    answers: [
      'wydamy tyle pieniędzy, ile zaplanowaliśmy',
      'z listy zakupów będziemy mogli ułożyć słowa piosenki',
    ],
    answer: ['wydamy tyle pieniędzy, ile zaplanowaliśmy'],
  },
  {
    type: `choice`,
    question: `Miejsce, w którym nasze pieniądze są bezpieczne, to:`,
    answers: ['skarpetka w misie', 'pudełko po butach', 'konto w banku'],
    answer: ['konto w banku'],
  },
  {
    type: `bool`,
    question: `Oszczędzanie pieniędzy to ważna umiejętność, która pozwala nam realizować nasze marzenia. `,
    answer: true,
  },
  {
    type: `choice`,
    question: `Jak możemy oszczędzać pieniądze?`,
    answers: [
      'ustalamy, na co chcemy zaoszczędzić pieniądze',
      'nie wydajemy pieniędzy nierozsądnie',
      'obie odpowiedzi są poprawne',
    ],
    answer: ['obie odpowiedzi są poprawne'],
  },
  {
    type: `choice`,
    question: `Sposobem na codzienne oszczędzanie NIE jest:`,
    answers: [
      'zakręcanie kurka z wodą, gdy myjesz zęby',
      'wyrzucanie jedzenia',
      'szybki prysznic zamiast kąpieli',
    ],
    answer: ['wyrzucanie jedzenia'],
  },
  {
    type: `bool`,
    question: `Oszczędzanie jest wtedy, gdy odkładamy pieniądze w jedno miejsce i nie wydajemy ich od razu.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Pożyczkę musimy spłacić na czas, w ustalonym z bankiem terminie. `,
    answer: true,
  },
  {
    type: `bool`,
    question: `Karta płatnicza to karta na codzienne wydatki.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Karta kredytowa to karta do kupowania czegoś ekstra, za co możemy zapłacić trochę później.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Trzeba dbać o swoje finanse, bo:`,
    answers: [
      'dobrze jest móc sobie kupić to, co potrzebne',
      'dobrze jest mieć oszczędności',
      'obie odpowiedzi są poprawne',
    ],
    answer: ['obie odpowiedzi są poprawne'],
  },
]

export const QUESTIONS_HARD = [
  {
    type: `choice`,
    question: `Dziś pieniądze inaczej to…`,
    answers: ['hajs', 'sieczka', 'zboże', 'snopki'],
    answer: ['hajs'],
  },
  {
    type: `bool`,
    question: `Nazwa pieniądza w danym państwie to waluta.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Choć bierzemy pieniądze z bankomatu, to w rzeczywistości są one:`,
    answers: [
      'przedmiotem do zabawy',
      'nagrodą za wykonaną pracę',
      'pomocą w liczeniu ',
      'kolorowym papierkiem',
    ],
    answer: ['nagrodą za wykonaną pracę'],
  },
  {
    type: `choice`,
    question: `Każdy pieniądz ma dwie strony:`,
    answers: ['dobrą i złą', 'awers i rewers', 'czoło i plecy', 'przód i tył'],
    answer: ['awers i rewers'],
  },
  {
    type: `choice`,
    question: `Kto pierwszy na świecie zaczął wytwarzać pieniądze?`,
    answers: ['Ateńczycy', 'Rzymianie', 'Fenicjanie', 'Persowie'],
    answer: ['Fenicjanie'],
  },
  {
    type: `choice`,
    question: `Kto może legalnie emitować pieniądze?`,
    answers: ['władca kraju', 'banki narodowe', 'dyrektor mennicy'],
    answer: ['banki narodowe'],
  },
  {
    type: `choice`,
    question: `Nazwa „bank” pochodzi od włoskiego słowa „banco”, czyli:`,
    answers: ['biały', 'sklepik', 'ławka', 'transakcja'],
    answer: ['ławka'],
  },
  {
    type: `choice`,
    question: `Mennica Polska znajduje się w:`,
    answers: ['Krakowie', 'Gdańsku', 'Warszawie', 'Poznaniu'],
    answer: ['Warszawie'],
  },
  {
    type: `choice`,
    question: `Nauka pomocnicza w historii zajmująca się badaniem monet, banknotów i innych znaków pieniężnych to:`,
    answers: ['monetologia', 'bankologia', 'numizmatyka'],
    answer: ['numizmatyka'],
  },
  {
    type: `choice`,
    question: `Przodkowie pieniądza to metale. Jakie?`,
    answers: [
      'aluminium, srebro i złoto',
      'żelazo, złoto i srebro',
      'brąz, srebro i złoto',
    ],
    answer: ['brąz, srebro i złoto'],
  },
  {
    type: `bool`,
    question: `Pierwsi bankierzy to złotnicy, u których zostawiano kwity depozytowe.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Na polskim banknocie widnieje wizerunek pierwszej polskiej monety. Co to za moneta?`,
    answers: ['szeląg', 'grosz', 'dukat', 'denar'],
    answer: ['denar'],
  },
  {
    type: `choice`,
    question: `Kiedy i gdzie na świecie pojawiły się pierwsze banknoty?`,
    answers: [
      'w Chinach w VII wieku n.e.',
      'w Rzymie w VI wieku n.e.',
      'w Atenach w I wieku',
    ],
    answer: ['w Chinach w VII wieku n.e.'],
  },
  {
    type: `choice`,
    question: `Wytwórnia Papierów Wartościowych to miejsce, gdzie…`,
    answers: [
      'wybija się monety',
      'wybija się monety i drukuje banknoty',
      'drukuje się banknoty',
    ],
    answer: ['drukuje się banknoty'],
  },
  {
    type: `bool`,
    question: `Rozdrobnione i poddane recyklingowi banknoty są dodatkiem do opału.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Skarbonka w formie świnki pochodzi od słowa „pygg”, oznaczającego glinę, z której powstawały naczynia na pieniądze.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `W świetle UV na banknocie 10 zł z portretem Mieszka I pojawiają się drugie wąsy.`,
    answer: false,
  },
  {
    type: `choice`,
    question: `W przeszłości nazywano ją białym złotem z powodu wysokiej wartości.`,
    answers: ['woda', 'sól', 'kasza manna', 'korzeń pietruszki'],
    answer: ['sól'],
  },
  {
    type: `bool`,
    question: `Gdy ceny rosną, to pieniądze zmieniają swoją wartość.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Przejawem dbałości o finanse osobiste jest…`,
    answers: [
      'wydawanie na wszystko, czego akurat teraz potrzebuję',
      'wydawanie pieniędzy tak, aby na koniec miesiąca było zero na koncie',
      'planowanie wydatków tak, aby zostały też oszczędności',
    ],
    answer: ['planowanie wydatków tak, aby zostały też oszczędności'],
  },
  {
    type: `choice`,
    question: `Najlepsze sposoby oszczędzania pieniędzy to:`,
    answers: [
      'konto oszczędnościowe lub lokata w banku',
      'skarpeta w szafie',
      'świnka skarbonka',
    ],
    answer: ['konto oszczędnościowe lub lokata w banku'],
  },
  {
    type: `choice`,
    question: `Najlepiej oszczędzać pieniądze, ale gdy ich zabraknie, a bardzo potrzebujemy, to`,
    answers: [
      'pożyczamy z banku',
      'obrabiamy bank',
      'sprzedajemy nasze najlepsze rzeczy',
    ],
    answer: ['pożyczamy z banku'],
  },
  {
    type: `choice`,
    question: `W co można inwestować, będąc nastolatkiem?`,
    answers: [
      'w zakup deskorolki',
      'w jedzenie pizzy',
      'w rozwijanie swoich pasji, uprawianie sportu, naukę języków obcych',
    ],
    answer: [
      'w rozwijanie swoich pasji, uprawianie sportu, naukę języków obcych',
    ],
  },
  {
    type: `choice`,
    question: `Czy inwestowanie wiąże się z ryzykiem?`,
    answers: [
      'nie, bo to przecież świetna zabawa',
      'tak, bo nigdy nie wiadomo, czy uda się osiągnąć zakładane zyski i czy sytuacja na to pozwoli',
    ],
    answer: [
      'tak, bo nigdy nie wiadomo, czy uda się osiągnąć zakładane zyski i czy sytuacja na to pozwoli',
    ],
  },
  {
    type: `bool`,
    question: `Karta płatnicza to inaczej karta kredytowa.`,
    answer: false,
  },
  {
    type: `choice`,
    question: `Karta płatnicza jest zabezpieczona:`,
    answers: [
      'kodem CCV',
      'numerem PIN',
      'hologramem',
      'wszystkie odpowiedzi są prawidłowe',
    ],
    answer: ['wszystkie odpowiedzi są prawidłowe'],
  },
  {
    type: `choice`,
    question: `Żeby zapłacić zbliżeniowo za zakupy w sklepie, potrzebujesz mieć…`,
    answers: [
      'konto, kartę płatniczą i pieniądze na koncie',
      'tylko konto i pieniądze na koncie',
      'gotówkę',
    ],
    answer: ['konto, kartę płatniczą i pieniądze na koncie'],
  },
  {
    type: `choice`,
    question: `Z pustego i Salomon nie naleje. Co przelewa się z konta na konto?`,
    answers: ['banknot', 'walutę', 'środki finansowe'],
    answer: ['środki finansowe'],
  },
  {
    type: `bool`,
    question: `Czy to prawda, że każdą kartą płatniczą dokonasz transakcji internetowej?`,
    answer: false,
  },
  {
    type: `choice`,
    question: `Bankomat to sejf i komputer w jednym. Dostępne są w nim do wypłaty…`,
    answers: ['banknoty i monety', 'tylko banknoty', 'tylko monety'],
    answer: ['tylko banknoty'],
  },
  {
    type: `bool`,
    question: `Przed wypłatą pieniędzy bankomat kontaktuje się z Twoim bankiem.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `O tym, ile masz pieniędzy na koncie, mówi Ci w bankomacie:`,
    answers: ['terminal', 'saldo', 'numer PIN'],
    answer: ['saldo'],
  },
  {
    type: `bool`,
    question: `W bankomacie można sprawdzić historię swoich wypłat.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Czy to możliwe, żeby w bankomacie znajdowało się milion złotych?`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Mieszko I z nudów zamienił się miejscami z synem Bolesławem Chrobrym. Na jakim banknocie się znalazł?`,
    answers: ['100 zł', '50 zł', '20 zł'],
    answer: ['20 zł'],
  },
  {
    type: `choice`,
    question: `Alek ma w kieszeni trzy banknoty o łącznej wartości 70 złotych. Jeden banknot jest w dwóch egzemplarzach. Jakie to banknoty?`,
    answers: [
      '50 zł + 10 zł + 10 zł',
      '20 zł + 20 zł + 30 zł',
      '30 zł + 30 zł + 10 zł',
    ],
    answer: ['50 zł + 10 zł + 10 zł'],
  },
  {
    type: `bool`,
    question: `Budżet domowy to wpływy (pieniądze zarabiane przez dorosłych) oraz wydatki wszystkich członków rodziny.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Wynagrodzenie to inaczej pensja, czyli suma pieniędzy, którą dorośli otrzymują za wykonywaną przez siebie pracę.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Finanse osobiste to:`,
    answers: [
      'suma pieniędzy, które możesz wydać na co chcesz',
      'pieniądze, o których nigdy nikomu nie mówisz',
      'planowanie, co zrobimy z naszymi pieniędzmi (wydatki oraz oszczędności)',
    ],
    answer: [
      'planowanie, co zrobimy z naszymi pieniędzmi (wydatki oraz oszczędności)',
    ],
  },
  {
    type: `bool`,
    question: `Zanim wybierzesz się na zakupy spożywcze, warto stworzyć listę potrzebnych produktów.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Oszczędzaniu pieniędzy służy: `,
    answers: [
      'zaplanowanie, na co chcemy oszczędzać pieniądze',
      'ograniczenie zbędnych wydatków',
      'obie odpowiedzi są poprawne',
    ],
    answer: ['obie odpowiedzi są poprawne'],
  },
  {
    type: `choice`,
    question: `Jak możemy oszczędzać pieniądze?`,
    answers: [
      'ustalamy, na co chcemy zaoszczędzić pieniądze',
      'nie wydajemy pieniędzy nierozsądnie ',
      'obie odpowiedzi są poprawne',
    ],
    answer: ['obie odpowiedzi są poprawne'],
  },
  {
    type: `choice`,
    question: `Sposobem na codzienne oszczędzanie NIE jest:`,
    answers: [
      'zakręcanie kurka z wodą, gdy myjesz zęby',
      'wyrzucanie jedzenia',
      'wyłączanie nieużywanej ładowarki z kontaktu',
    ],
    answer: ['wyrzucanie jedzenia'],
  },
  {
    type: `bool`,
    question: `Oszczędzanie jest wtedy, gdy rezygnujemy z drobnych wydatków na rzecz innego, zazwyczaj większego zakupu. `,
    answer: true,
  },
  {
    type: `bool`,
    question: `Pieniądze możemy oszczędzać, odkładając je na specjalne konto w banku, czyli konto oszczędnościowe.`,
    answer: true,
  },
  {
    type: 'choice',
    question: `Na konto oszczędnościowe możemy wpłacać i wypłacać oszczędzane pieniądze, kiedy chcemy.`,
    answer: true,
  },
  {
    type: `choice`,
    question: `Obligacje to inaczej…`,
    answers: [
      'wątpliwości ',
      'papiery wartościowe',
      'czynności, które musisz wykonać',
    ],
    answer: ['papiery wartościowe'],
  },
  {
    type: `choice`,
    question: `Papiery wartościowe to dokumenty, które:`,
    answers: [
      'są wykonane z kosztownych materiałów',
      'wypłacasz z bankomatu',
      'świadczą o tym, że jesteś współwłaścicielem dóbr',
    ],
    answer: ['świadczą o tym, że jesteś współwłaścicielem dóbr'],
  },
  {
    type: `bool`,
    question: `Kredyt to pożyczka od banku, którą musimy spłacić w wyznaczonym terminie. `,
    answer: true,
  },
  {
    type: `bool`,
    question: `Wkład własny to suma pieniędzy, którą musimy posiadać, gdy ubiegamy się o duży kredyt, np. na zakup mieszkania.`,
    answer: true,
  },
  {
    type: `bool`,
    question: `Z pomocą karty kredytowej możemy wydawać tylko tyle, ile mamy w danym momencie na naszym rachunku bankowym.`,
    answer: false,
  },
]
