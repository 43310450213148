import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { Howl } from 'howler'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { QuizDesc, QuizButton } from './quiz-elements'
import { dlQuizEvent } from '../events'

import quizStartTitleBG from '../../images/quiz/quiz_start_title_bg.svg'

const QuizStartWrapper = styled.div`
  padding-bottom: 50px;
  @media (min-width: 1200px) {
    margin-top: -8%;
  }
  @media (max-width: 575px) {
    overflow: hidden;
  }
`

const QuizStartTitle = styled.h2`
  background-image: url(${quizStartTitleBG});
  background-repeat: no-repeat;
  background-position: center;
  background-position-x: 35%;
  background-size: contain;
  color: transparent;
  font-size: 100px;
  margin-bottom: 40px;
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 80px;
    margin-bottom: 30px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 60px;
    margin-bottom: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 50px;
    margin-bottom: 25px;
  }
  @media (max-width: 575px) {
    font-size: 41px;
    margin-bottom: 15px;
  }
  @media (max-width: 340px) {
    font-size: 39px;
  }
`

const QuizStartDesc = styled(QuizDesc)``

const QuizStartButtonWrapper = styled(Col)`
  display: flex;
  justify-content: center;
`

const QuizStartButton = styled(QuizButton)`
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 32px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 26px;
    padding-left: 25px;
    padding-right: 25px;
  }
  @media (max-width: 575px) {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 15px;
  }
`

const QuizStart = (props) => {
  const buttonAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_kliknij_start.mp3'),
  })
  const initialAudio = new Howl({
    src: withPrefix('/sounds/BNP_Quiz_podejmij_wyzwanie.mp3'),
  })

  useEffect(() => {
    if (props.withSound) {
      initialAudio.play()
      initialAudio.on('end', () => {
        setTimeout(() => {
          buttonAudio.play()
        }, 1000)
      })
    }
  }, [])

  const setIsWithSound = () => {
    initialAudio.stop()
    buttonAudio.stop()
    props.changeStep()
  }

  const handleStartButtonClick = () => {
    dlQuizEvent({
      action: 'Kliknięcie w button - START',
      label: 'Quiz: Podejmij wyzwanie',
    })
    setIsWithSound()
  }

  return (
    <QuizStartWrapper>
      <Container>
        <Row>
          <Col xs={12}>
            <QuizStartTitle>podejmij wyzwanie</QuizStartTitle>
          </Col>
          <Col xs={12}>
            <QuizStartDesc>
              <p>Kto wie więcej o bankowaniu? Ty czy Twoi bliscy?</p>
              <p>
                Teraz możesz to sprawdzić. Podejmij wyzwanie i odpowiedz na
                pytania quizu. A potem rzuć wyzwanie najbliższym i porównajcie
                wyniki.
              </p>
              <p>
                W tej rywalizacji każdy jest wygrany, bo nawet najdrobniejsza
                porcja wiedzy pomaga budować samodzielność.
              </p>
            </QuizStartDesc>
          </Col>
          <QuizStartButtonWrapper xs={12}>
            <QuizStartButton
              onClick={handleStartButtonClick}
              id="quiz-start-button"
            >
              Start
            </QuizStartButton>
          </QuizStartButtonWrapper>
        </Row>
      </Container>
    </QuizStartWrapper>
  )
}

export default QuizStart
